<template>
  <section>
    <b-card no-body>
      <div
        class="custom-search d-flex justify-content-between align-items-center mb-2 px-2 pt-2"
      >
        <h2 class="m-0 font-weight-bolder">
          {{ $t('Global.All') }} {{ $t('Management.Customers.Customers') }}
        </h2>
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
          </div>
          <Create
            v-if="$can($acl.action.Create, $acl.subjects.Customers)"
            :countries="countries"
            @add="addEntry"
            @openCountryModal="openCountryModal"
          />
        </div>
      </div>

      <Table :rows="rows" :countries="countries" :search-term="searchTerm" @delete="deleteRow" @openCountryModal="openCountryModal" />
    </b-card>
    <CountryModal 
      ref="modal"
      v-if="$can($acl.action.Create, $acl.subjects.Countries)" 
      :can="$can($acl.action.Create, $acl.subjects.Countries)" 
      @submitCountry="submitCountry"
    />
  </section>
</template>

<script>
import { BFormInput, BCard } from 'bootstrap-vue'

import deleteEntry from '@/utility/scripts/delete'
import CountryModal from '@/views/loggedin/management/countries/country-modal.vue'
import Create from './components/create.vue'
import Table from './components/table.vue'

export default {
  components: {
    BFormInput,
    BCard,
    CountryModal,
    Create,
    Table,
  },

  data: () => ({
    searchTerm: '',
    rows: [],
    countries: [],
  }),

  created() {
    this.load()
  },

  methods: {
    openCountryModal() {
      this.$refs.modal.modalVisible = true
    },
    async load() {
      try {
        this.loadCountries();
        this.loadCustomers();
      } catch (error) {
        console.error(error);
      }
    },
    async loadCustomers() {
      const { data } = await this.$axios.get('customers');
      this.rows = data;
    },
    async loadCountries() {
      const { data } = await this.$axios.get('countries');
      this.countries = data;
      const newCountryOption = [{
        id:"0",
        name: this.$tc("Global.New", 4) + " " + this.$t(`Global.${'Create'}`,{ subject: this.$t('Management.Countries.Country') },),
        short:"neu",
      }];
      this.countries = [].concat(newCountryOption, this.countries)
    },
    submitCountry(entry){
      this.countries.push(entry);
      this.$refs.modal.modalVisible = false;
    },
    addEntry(entry) {
      this.rows.push(entry);
    },

    async deleteRow(row) {
      try{
        await deleteEntry(this, {
          id: row.id,
          subject: `${this.$t('Management.Customers.Customer')}`,
          article: `${this.$t('Global.Articles_4')}`,
          individual: `"${row.name}"`,
          endpoint: 'customers/',
        });
      this.load();
      } catch (error) {
        // continue regardless of error
      }
    },
  },
}
</script>
