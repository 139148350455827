<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="DatabaseIcon" size="21" />
      {{ $tc('Global.New', 0) }} {{ $t('Management.Customers.Customer') }}
    </b-button>
    <Form
      ref="form"
      :countries="countries"
      :customer="customer"
      :can="$can($acl.action.Create, $acl.subjects.Customers)"
      :header="$tc('Global.New', 0) + ' ' + $t('Management.Customers.Customer')"
      @submit="submit"
      @reset="reset"
      @openCountryModal="openCountryModal"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Form from './form.vue'

export default {
  components: {
    Form,
    BButton,
  },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    customer: {
      name: null,
      street: null,
      zip: null,
      city: null,
      country: null,
    },
  }),

  methods: {
    reset() {
      this.customer = {
        name: null,
        street: null,
        zip: null,
        city: null,
        country: null,
      }
    },
    openCountryModal() {
      this.$emit("openCountryModal");
    },
    submitCountry(entry){
      this.$emit("submitCountry", entry);
    },
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        this.customer.country = this.customer.country.id
        const { data } = await this.$axios.post('customers', this.customer)

        this.$alert.create(`${data.name}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
