<template>
  <span>
    <feather-icon
      v-b-tooltip.hover.top="
        $t(
          `Global.${
            $can($acl.action.Update, $acl.subjects.Customers)
              ? 'EditSubject'
              : 'ViewSubject'
          }`,
          { subject: $t('Management.Customers.Customer') },
        )
      "
      :icon="
        $can($acl.action.Create, $acl.subjects.Customers)
          ? 'Edit2Icon'
          : 'EyeIcon'
      "
      size="16"
      class="hover-primary"
      @click="openSideBar"
    />
    <Form
      ref="form"
      :countries="countries"
      :customer="updatedData"
      :submit-btn-text="`${$t('Global.Save')} ${$t(
        'Management.Customers.Customer',
      )}`"
      :can="$can($acl.action.Create, $acl.subjects.Customers)"
      :header="$t('Management.Customers.Customer') + ' ' + $tc('Global.EditSubject')"
      @submit="submit"
      @openCountryModal="openCountryModal"
    />
  </span>
</template>

<script>
import Form from './form.vue'

export default {
  components: {
    Form,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    updatedData: null,
  }),

  methods: {
    openCountryModal() {
      this.$emit("openCountryModal");
    },
    openSideBar() {
      this.updatedData = { ...this.customer }
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        this.updatedData.country = this.updatedData.country?.id
        const { data } = await this.$axios.patch(
          `customers/${this.customer.id}`,
          this.updatedData,
        )
        this.updatedData.country = this.countries.find(c => c.id === this.updatedData.country)
        this.$alert.update(data.name)

        Object.keys(this.updatedData).forEach(key => {
          this.customer[key] = this.updatedData[key]
        })

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
